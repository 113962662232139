<template>
  <div v-loading="loading" class="table-area history-area">
    <div  class="table-top">
    <el-row>
      <el-col :xs="24" :sm="6" :md="6" :lg="6" :xl="6">
        <h4 class="heading heading-active">Close Positions</h4>
      </el-col>
      <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
        <div v-if="getRoleSlug == 'admin' || getRoleSlug == 'broker' " class="search-wrapper">
            <el-input
              placeholder="Search"
              v-model="listQuery.search"
              class="input-with-select"
              style="width:500px;"
            >
              <el-select
                slot="prepend"
                v-model="listQuery.search_type_client"
                placeholder="select"
                clearable
                filterable
                style="width: 150px"
                @change="handleSelectSearchType()"
              >
                <el-option
                  v-for="item in listDataDropdownClients"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
              <el-button
                slot="append"
                icon="el-icon-search"
                @click="handleSearch()"
              ></el-button>
            </el-input>
        </div>
        <div v-else-if="getRoleSlug == 'superadmin'" class="search-wrapper">
          <el-input
            placeholder="Search"
            v-model="listQuery.search"
            class="input-with-select"
            style="width:500px;"
          >
            <el-select
              slot="prepend"
              v-model="listQuery.search_type"
              placeholder="select"
              clearable
              filterable
              style="width: 150px"
              @change="handleSelectSearchType()"
            >
              <el-option
                v-for="item in listAdmins"
                :key="item.master"
                :label="item.name"
                :value="item.master"
              />
            </el-select>
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="handleSearch()"
            ></el-button>
          </el-input>
        </div>
      </el-col>
      <el-col :xs="24" :sm="6" :md="6" :lg="6" :xl="6">
        <div class="btn-wrapper"></div>
      </el-col>
    </el-row>
    </div>
    <Table
      tableKey="closePositions"
      :tableConfig="config"
      :tableData="listData"
      :tableQuery="listQuery"
      :tableActionShowVisibility="false"
      :tableActionVisibility="false"
      :tableActionViewDetailsVisibility="true"
      :tableActionEditVisibility="false"
      :tableActionDeleteVisibility="false"
      :tableLoadingStatus.sync="loading"
      @pagination="handlePagination()"
      @view="handleViewDetails($event)"
    />
  </div>
</template>

<script>
import Table from "@/components/Table/Table.vue";
import { fetchList } from "@/api/close-position";
import getRoleSlug from '@/store/app.js'
import { mapGetters } from "vuex";
import { fetchAllAdminList } from "@/api/super-admin/admin";
import { fetchClientList } from "@/api/market-watch";



export default {
  name: "ListClosePositions",
  components: {
    Table,
  },
  data() {
    return {
      loading: false,
      listDataDropdownClients: {},
      config: [
        {
          label: "Script",
          prop: "script",
          width: "300",
          popover: true,
          className: "redFont",
        },
        {
          label: "Lots",
          prop: "unit_and_lot",
          width: "150",
          className: "redFont",
        },
        {
          label: "Avg buy rate",
          prop: "avg_buy",
          width: "",
          className: "blueFont",
        },
        {
          label: "Avg sell rate",
          prop: "avg_sell",
          width: "",
          className: "blueFont",
        },
        {
          label: "Profit / Loss",
          prop: "pl",
          width: "",
          className: "blueFont",
        },
        {
          label: "Brokerage",
          prop: "broking_charge",
          width: "",
          className: "blueFont",
        },
        {
          label: "Net P/L",
          prop: "nt_pl",
          width: "",
          className: "blueFont",
        },
      ],
      listData: {},
      listAdmins: {},
      listQuery: {
        page: 1,
        limit: 20,
        search: "",
        search_type_client:"",
        search_type: "",
        searchJoin: "or",
        orderBy: "created_at",
        sortedBy: "desc",
      },
      data: {},
    };
  },
  computed: {
    ...mapGetters(["getRoleSlug"]),
  },
  created() {
    if(getRoleSlug.state.roleSlug == 'superadmin') {
      this.getAdminList();
    }else if(getRoleSlug.state.roleSlug == 'admin' || getRoleSlug.state.roleSlug == 'broker'){
      this.getClientList();
    }
    this.getList();
  },
  methods: {
      handleViewDetails(data) {
        if(this.listQuery.search_type_client){
          return this.$router.push("/"+ getRoleSlug.state.roleSlug +"/trading-clients/trading-clients-view/" + this.listQuery.search_type_client);
        }else{
          return this.$router.push("/"+ getRoleSlug.state.roleSlug +"/close-trade-positions/" + data.stock_id);
        }
    },
    handleSelectSearchType() {
      this.handleSearch()
    },
    handleSearch() {
      this.listQuery.page = 1;
      this.getList();
    },
    // //////////////////
    // GET LIST DATA
    // //////////////////
     getList() {
      this.loading = true;
      fetchList(this.listQuery).then((response) => {
        this.listData = response.data;
        // console.log(this.listData)
        this.loading = false;
      });
    },
    getAdminList() {
      fetchAllAdminList().then((response) => {
        this.listAdmins  = response.data.data;
      });
    },
    getClientList() {
      this.loading = true;
      fetchClientList().then((response) => {
        this.listDataDropdownClients = response.data.data;
      });
    },
  },
};
</script>
